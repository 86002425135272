.webcam-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    margin: 0 auto;
}

.webcam-container .webcam-btn {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 38%;
}

/* Media queries ~ */
@media screen and (max-width:1000px) {
    .home-container .container {
        max-width: 90vw;
    }
}

@media screen and (max-width:678px) {

    .container {
        max-width: 80vw;
    }

    form {
        width: 90%;
    }

    .loginDesign {
        display: none;
    }

    .container .text {
        width: 100%;
        padding: 80px 20px;
    }
}